:root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

*:focus {
  outline: none;
}

html {
  font-size: 0.875rem;
}

ol {
  padding-left: 20px;
}

li,
ul {
  padding: revert;
  margin: revert;
}

.no-global-padding {
  padding: inherit;
}

ol li {
  list-style: inherit;
}

a {
  color: #9e9e9e;
  text-decoration: underline !important;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

label {
  margin-bottom: 0;
}

a:hover {
  color: #464646;
}

.ap-panel {
  min-height: inherit;
}

.a-tooltip {
  z-index: 99999;
}

.a-flex-column {
  flex-direction: column !important;
}

.a-input-text,
.a-date-picker-header,
.a-textarea,
.a-upload .a-upload-ajax-context,
.a-upload .a-upload-file-list-item {
  background-color: #fff;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

#root {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
}

.a-navigation-content {
  background-color: #fff;
}

ul.navigation-list {
  margin-bottom: 0;
}

/* Breadcrumbs styling adjustments for limiting space */

.ap-breadcrumb {
  border-bottom: none;
  z-index: 20;
  padding-bottom: 1.25rem;
  line-height: 1;
}

.ap-breadcrumb.negative .ap-breadcrumb-item:first-child .ap-breadcrumb-content {
  color: #fff !important;
}

.ap-breadcrumb.negative .ap-breadcrumb-content {
  color: #fff !important;
}

.ap-breadcrumb.negative .ap-breadcrumb-separator {
  color: #fff !important;
}

.bc-text {
  height: 30px;
  font-size: 24px;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ap-breadcrumb-item .ap-breadcrumb-content {
  color: #7d7d7d;
}

.ap-breadcrumb-item:first-child .ap-breadcrumb-content {
  color: #000 !important;
  font-weight: 700;
}

.ap-breadcrumb-item:last-child {
  pointer-events: none;
}

.ap-breadcrumb-item:last-child .ap-breadcrumb-content {
  color: #7d7d7d;
}

.ap-breadcrumb-item .ap-breadcrumb-separator {
  margin: 0 10px;
  color: #7d7d7d;
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 6px;
}

.a-dark-panel .a-tooltip-inner {
  background-color: rgba(70, 70, 70, 0.9) !important;
  border-radius: 4px;
}

.a-dark-panel .a-tooltip-arrow {
  border-right: 0.3125rem solid rgba(70, 70, 70, 0.9) !important;
}

.a-user-setting-panel-one {
  padding: 0.625rem;
}

.a-account-options {
  width: inherit;
}

.a-account-options .a-log-out-btn {
  display: block;
  border-top: none;
}

.a-account-options .logout-button {
  width: 100%;
  background-color: #fff;
  padding: 5px 10px;
  text-align: left;
}

.a-account-options .logout-button:hover {
  background-color: #e5e5e5;
}

.a-upload,
.upload-drop,
.upload-drop-small {
  width: 100%;
}

.a-input-number-wrapper {
  width: 250px;
}

.formatted-input {
  width: 250px;
  padding-right: 10px !important;
  padding: 10px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rt-td .formatted-input,
.rt-td .a-input-number-wrapper,
.rt-td .react-date-picker {
  width: 100%;
}

.react-date-picker,
.react-daterange-picker {
  width: 250px;
}

.a-calendar .a-date-picker-header {
  width: 250px;
}

.hidden-tab {
  display: none;
}

.messaging-wrapper .messaging .messaging-detail .messaging-content {
  background-color: #fff;
}

/* Appkit 4 styles modifications */

/* This is a temporary fix, preventing scaling issues in the calendar caused by the font size on the html element*/
.ap-calendar .ap-calendar__month-view .ap-days {
  gap: 5px;
}

.dashboard-columns .ap-panel-body {
  height: 100%;
}

.dashboard-row .ap-panel-body {
  height: 100%;
}

.dashboard-page-container .ap-panel-body {
  height: 100%;
}

.ap-tooltip-react {
  max-width: 30rem;
}

.ap-tooltip-react .ap-tooltip-reactContent {
  backdrop-filter: none;
  background-color: transparent;
}

.question_form-right-content .ap-tooltip-react {
  max-width: 60rem;
  width: 60rem;
}

.ap-tooltip-react .ap-list {
  width: 100% !important;
}

.a-upload-file-list {
  padding: 10px 5px;
}

.a-upload-file-list-item {
  margin-bottom: 5px;
}

.a-upload-file-list-item .icon-circle-delete-outline {
  margin-left: 20px;
  cursor: pointer;
}

.messaging-wrapper {
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
  line-height: 0;
  display: inline-block;
  width: 100%;
}

.messaging-wrapper .messaging {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.25rem;
}

.messaging-wrapper .messaging.messaging-right {
  justify-content: flex-start;
}

.messaging-wrapper .messaging.messaging-left {
  justify-content: flex-end;
}

.messaging-wrapper .messaging .messaging-name-short {
  min-width: 1.5rem;
  height: 1.5rem;
  background-color: #888;
  border-radius: 1.5rem;
  margin-bottom: 34px;
}

.messaging-wrapper .messaging .messaging-detail .messaging-info {
  margin-top: 0.625rem;
  line-height: 1.67;
}

.messaging-wrapper .messaging .messaging-detail .messaging-info .messaging-name {
  font-size: 0.75rem;
  font-weight: 500;
  color: #d04a02;
}

.messaging-wrapper .messaging .messaging-detail .messaging-info .messaging-time {
  font-size: 0.75rem;
  margin-left: 0.25rem;
  color: #9e9e9e;
}

.messaging-wrapper .messaging.messaging-right .messaging-detail {
  margin-left: 0.625rem;
}

.messaging-wrapper .messaging.messaging-left .messaging-detail {
  margin-left: 2.125rem;
  margin-right: 0.625rem;
}

.messaging-wrapper .messaging .messaging-detail {
  white-space: normal;
  max-width: 62.5rem;
}

.messaging-wrapper .messaging.messaging-right .messaging-detail .messaging-content {
  border-radius: 0.625rem 0.625rem 0.625rem 0;
}

.messaging-wrapper .messaging.messaging-left .messaging-detail .messaging-content {
  border-radius: 0.625rem 0.625rem 0 0.625rem;
}

.messaging-wrapper .messaging .messaging-detail .messaging-content {
  line-height: 1.71;
  padding: 1.25rem;
  overflow: auto;
  color: #464646;
}

.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists {
  margin-bottom: 10px;
}

.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-footer {
  justify-content: flex-end;
}

.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-footer-delete-attachment {
  display: none;
}

.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-wrapper:hover {
  background-color: #fff;
}

.page-filter-container .ap-tooltip-reactContent {
  padding: 0 !important;
  box-shadow: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
}

.data-subsets-container .ap-tooltip-reactContent {
  padding: 0 !important;
  box-shadow: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
}

.ap-tooltip-reactContent {
  background-color: rgba(70, 70, 70, 0.9) !important;
  border-radius: 4px;
}

label.ap-checkbox-label {
  margin-bottom: 0;
}

.ap-button.ap-button-compact {
  padding: 0.25rem 0.5rem !important;
}

.ap-button.right-header-link {
  margin-left: 10px;
}

.ap-button.ap-button-loading {
  min-width: inherit;
}

.ap-panel-extra {
  top: 16px;
}

/* Styling fix for upload file fields in tables */
.ReactTable .upload-drop-span {
  display: none;
}

.ReactTable .a-upload {
  width: inherit;
}

.ReactTable .link-pdf-text {
  font-weight: 400;
  margin-right: 5px;
}

.ReactTable .link-pdf {
  cursor: pointer;
  text-decoration: underline;
}

.ReactTable .link-pdf:hover {
  color: #7d7d7d;
}

.ReactTable .rt-noData {
  position: relative;
  left: 0;
  padding: 20px;
  top: 0;
  transform: none;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.table-element .ReactTable {
  height: 100%;
}

.ap-table {
  box-shadow: none;
}

.ap-table table thead th .header-container {
  height: inherit;
}

.ap-tabset-container {
  grid-row-gap: 30px;
  height: 100%;
}

/* Styling fix Appkit4 tabs visibility error */
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.disabled {
  display: none;
}

.ap-tabset-container .ap-tabset-content {
  height: 100%;
  overflow: scroll;
}

.dashboard-tabs .ap-tabset-content > div {
  height: 100%;
}

.ap-panel-head-title {
  font-size: 1rem;
  white-space: initial;
  overflow: visible;
}

.ap-panel-extra .ap-button.ap-button-compact {
  font-size: 12px;
}

.ap-panel-head.has-extra {
  width: 80%;
  max-width: 60%;
}

.markdown ul,
.markdown ol,
.markdown dl {
  padding-left: 25px;
}

.markdown dd,
.markdown dl,
.markdown dt,
.markdown li,
.markdown ul {
  list-style: unset;
}

.markdown em {
  font-style: italic;
}

.markdown pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}

.markdown blockquote {
  padding: 0 1em;
  color: #4c5158;
  border: none;
  border-left: 0.25em solid #dfe2e5;
}

/* Styling for tags input field */

.rti--container {
  --rti-radius: 0.125rem;
  --rti-border: #dedede;
  --rti-main: #d04a02;
}

.dots-animation {
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: dots 1s infinite linear alternate;
  margin: 5px 10px;
}

@keyframes dots {
  0% {
    box-shadow: 10px 0 #000, -10px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 10px 0 #000, -10px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 10px 0 #0002, -10px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 10px 0 #0002, -10px 0 #000;
    background: #000;
  }
}

/* Media queries for prints */

@media print {
  @page {
    size: A4;
  }

  html,
  body {
    -webkit-print-color-adjust: exact;
    width: 210mm;
    height: 282mm;
    font-size: 11px;
    background: #fff;
    overflow: initial !important;
  }

  .component-filters,
  .dashboard-page-container > .dashboard-row .ap-panel .ap-panel-header-right .a-btn {
    display: none !important;
  }

  .dashboard-page-container {
    display: block !important;
  }

  .dashboard-page-container > .dashboard-row:not(:last-child) {
    margin-bottom: 20px !important;
  }

  * {
    position: relative !important;
    page-break-before: auto !important;
    page-break-after: auto !important;
    page-break-inside: avoid !important;
  }

  .dashboard-tabs {
    height: 100% !important;
  }

  .ap-panel .a-tabs-wrapper .a-tab-bar-baseline,
  .ap-panel .a-tabs-wrapper .a-tab-bar-indicator,
  .ap-panel .a-tabs-wrapper .a-tab-bar-item:not(.active) {
    display: none;
  }

  .ap-panel .a-tabs-wrapper .a-tab-bar-item.active {
    color: #2d2d2d;
  }

  .ap-panel .a-tabs-wrapper .a-tab-bar-wrapper {
    border-bottom: 1px solid #eeeeee;
    margin-right: 0 !important;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }
}

.dashboard-page-container div::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

.dashboard-page-container div::-webkit-scrollbar-track,
.dashboard-page-container div::-webkit-scrollbar-track:hover {
  background-color: #fff;
}

.dashboard-page-container div::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}

.dashboard-page-container div::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.dashboard-page-container div::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar {
  width: 0.7em;
  background: white;
  position: absolute;
}
*::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  height: 30px;
}

*::-webkit-scrollbar-track-piece {
  display: none;
}

*::-webkit-scrollbar {
  width: 0.7em;
  background: white;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  overflow: auto;
}

.react-pdf__Page {
  margin: 0 20px;
}

.react-pdf__Page__textContent.textLayer span mark {
  padding: 0;
}

/* After migrating to v4 */
.a-shadow-sm {
  -webkit-box-shadow: 0 0.1875rem 0.4375rem 0.0625rem rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 0.1875rem 0.4375rem 0.0625rem rgba(0, 0, 0, 0.16) !important;
}
/* Left nav tooltips */
.a-language-options {
  border: 1px solid lightgray;
  background-color: white !important;
}

.a-language-options > div {
  background-color: white !important;
}

.a-account-options {
  border: 1px solid lightgray;
  background-color: white !important;
}

.a-account-options > div {
  background-color: white !important;
}

/* Forms */
.rti--tag {
  background: #dedede !important;
}

.upload-drop,
.upload-drop-small {
  border: 0.0625rem dashed #bdbdbd;
  border-radius: 0.125rem;
  padding: 20px;
}
.upload-browse-span {
  color: #d04a02;
}

.ap-tabset-toggle > span {
  font-size: 14px;
}
/* Input */
.ap-field-wrapper.keyboard-focus.focus {
  outline-color: rgb(208, 74, 2) !important;
}

/**React Table Styles */

.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: none;
}

.ReactTable.lg .rt-td,
.ReactTable.lg .rt-th {
  font-size: 1rem;
  line-height: 3.125rem;
}

.ReactTable * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ReactTable .rt-table {
  -ms-flex: auto 1;
  flex: auto 1;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: visible;
}

.ReactTable .rt-table,
.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.ReactTable .rt-thead {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters.out {
  margin-bottom: 0.625rem;
}

.ReactTable .rt-thead.-filters.out .rt-th {
  overflow: visible;
  line-height: unset;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 0.3125rem 0.4375rem;
  font-size: inherit;
  border-radius: 0.1875rem;
  font-weight: 400;
  outline: none;
}

.ReactTable .rt-thead.-filters .rt-th {
  padding: 0.125rem;
  padding-right: 0.625rem;
}

.ReactTable .rt-thead.-header {
  background-color: #e0e0e0;
  color: #000000;
  font-weight: 500;
  box-shadow: unset !important;
}

.a-theme-container[theme='theme-dark'] .ReactTable .rt-thead.-header {
  background-color: #252525;
  color: rgba(255, 255, 255, 0.87);
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
  font-size: 0.875rem;
  line-height: 2.125rem;
  padding: 0 0.625rem;
  position: relative;
  -webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: -webkit-box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    -webkit-box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-td.-cursor-pointer,
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.ReactTable .rt-thead .rt-td:last-child,
.ReactTable .rt-thead .rt-th:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:not(:last-child):hover {
  border-right: 0.0625rem solid #7d7d7d;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header:last-child .rt-resizer {
  cursor: unset;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 0.5rem;
  margin-top: -0.5rem;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 0.625rem;
  margin-top: -0.625rem;
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  background-color: transparent;
}

.a-theme-container[theme='theme-dark'] .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level1'] .a-increase-child-color-level-by-1 .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level1'] .a-increase-child-color-level-by-2 .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level2'] .a-increase-child-color-level-by-1 .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level2'] .a-increase-child-color-level-by-2 .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level2'] .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level3'] .a-increase-child-color-level-by-1 .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level3'] .a-increase-child-color-level-by-2 .ReactTable .rt-tbody,
.a-theme-container[theme='theme-dark'][level='level3'] .ReactTable .rt-tbody {
  background-color: #474747;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex: 0;
}

.ReactTable .rt-th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 2.125rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 0.625rem;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-td.-hidden,
.ReactTable .rt-th.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  color: transparent;
  display: inline-block;
  margin: 0 0.625rem;
  position: relative;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 0.315rem solid transparent;
  border-right: 0.315rem solid transparent;
  border-top: 0.4375rem solid rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(0deg);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 2.25rem;
  top: 0;
  bottom: 0;
  right: -1.125rem;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background-color: #f5f5f5 !important;
}

.a-theme-container[theme='theme-dark'] .ReactTable.-striped .rt-tr.-odd {
  background-color: #474747;
}

.ReactTable.-striped .rt-tr.-even {
  background-color: #f5f5f5 !important;
}

.a-theme-container[theme='theme-dark'] .ReactTable.-striped .rt-tr.-even {
  background-color: #3b3b3b;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #ffe6a2;
  color: #000000;
}

.a-theme-container[theme='theme-dark'] .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #ffecbd;
  color: #252525;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .a-checkbox-wrapper .a-checkbox-inner {
  border-color: #ffffff;
}

.a-theme-container[theme='theme-dark']
  .ReactTable.-highlight
  .rt-tbody
  .rt-tr:not(.-padRow):hover
  .a-checkbox-wrapper
  .a-checkbox-inner {
  border-color: #000000;
}

.ReactTable .pagination-bottom {
  margin-top: 0.625rem;
}

.ReactTable .-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ReactTable .-pagination input {
  color: #2d2d2d;
  background-color: #f5f5f5;
  height: 1.5rem;
  line-height: 1;
  text-align: right;
  font-weight: 500;
  padding: 0 0.625rem;
  border-radius: 0.125rem;
  width: 2.6875rem;
  border: 0.0625rem solid #dedede;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination input {
  color: rgba(255, 255, 255, 0.87);
  background-color: #252525;
}

.a-theme-container[theme='theme-dark'][level='level2'] .ReactTable .-pagination input {
  background-color: #303030;
}

.a-theme-container[theme='theme-dark'][level='level3'] .ReactTable .-pagination input {
  background-color: #3b3b3b;
}

.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  input {
  background-color: #303030;
}

.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  input {
  background-color: #3b3b3b;
}

.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  input {
  background-color: #474747;
}

.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  input {
  background-color: #3b3b3b;
}

.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  input,
.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  input {
  background-color: #474747;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination input {
  border: 0.0625rem solid #474747;
}

.ReactTable .-pagination input:focus {
  -webkit-box-shadow: 0 0 0 0.125rem #d04a02;
  box-shadow: 0 0 0 0.125rem #d04a02;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination input:focus {
  border: none;
}

.ReactTable .-pagination select {
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 0.3125rem 0.4375rem;
  font-size: inherit;
  border-radius: 0.1875rem;
  font-weight: 400;
  outline: none;
}

.ReactTable .-pagination .-btn:focus:not(.without-outline) {
  z-index: 999;
  outline: 0.0625rem dotted #444;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ReactTable .-pagination .-btn:focus:not(.without-outline) {
    outline: 0.0625rem solid #4d90fe;
  }
}

@supports (-ms-ime-align: auto) {
  .ReactTable .-pagination .-btn:focus:not(.without-outline) {
    outline: 0.0625rem solid #4d90fe;
  }
}

.ReactTable .-pagination .-btn.without-outline {
  outline: none;
}

.ReactTable .-pagination .-btn {
  font-size: 13px;
  padding: 5px;
  width: 65px;
  max-height: 34px;
  background: #d04a02 !important;
  color: #fff;
}

.ReactTable .-pagination .-btn[disabled] {
  cursor: not-allowed;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background-color: #ee5300 !important;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-btn:not([disabled]):hover {
  background-color: #474747;
}

.ReactTable .-pagination .-btn:not([disabled]):active {
  background-color: #eeeeee;
}

.ReactTable .-pagination .-next,
.ReactTable .-pagination .-previous {
  flex: 0;
}

.ReactTable .-pagination .-next .appkiticon,
.ReactTable .-pagination .-previous .appkiticon {
  color: #9e9e9e;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-next .appkiticon,
.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-previous .appkiticon {
  color: #e45c2b;
}

.ReactTable .-pagination .-center,
.ReactTable .-pagination .-next,
.ReactTable .-pagination .-previous {
  background-color: #ffffff;
  margin-right: 0.0625rem;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-center,
.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-next,
.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-previous {
  background-color: #191919;
}

.a-theme-container[theme='theme-dark'][level='level2'] .ReactTable .-pagination .-center,
.a-theme-container[theme='theme-dark'][level='level2'] .ReactTable .-pagination .-next,
.a-theme-container[theme='theme-dark'][level='level2'] .ReactTable .-pagination .-previous {
  background-color: #252525;
}

.a-theme-container[theme='theme-dark'][level='level3'] .ReactTable .-pagination .-center,
.a-theme-container[theme='theme-dark'][level='level3'] .ReactTable .-pagination .-next,
.a-theme-container[theme='theme-dark'][level='level3'] .ReactTable .-pagination .-previous {
  background-color: #303030;
}

.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-center,
.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-next,
.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-previous {
  background-color: #252525;
}

.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-center,
.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-next,
.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-previous {
  background-color: #303030;
}

.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-center,
.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-next,
.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-1
  .ReactTable
  .-pagination
  .-previous {
  background-color: #3b3b3b;
}

.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-center,
.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-next,
.a-theme-container[theme='theme-dark'][level='level1']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-previous {
  background-color: #303030;
}

.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-center,
.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-next,
.a-theme-container[theme='theme-dark'][level='level2']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-previous {
  background-color: #3b3b3b;
}

.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-center,
.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-next,
.a-theme-container[theme='theme-dark'][level='level3']
  .a-increase-child-color-level-by-2
  .ReactTable
  .-pagination
  .-previous {
  background-color: #474747;
}

.ReactTable .-pagination .-center {
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: center;
}

.ReactTable .-pagination .-pageInfo {
  color: #464646;
  font-size: 0.75rem;
  padding: 0 0.625rem 0 0.3125rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.a-theme-container[theme='theme-dark'] .ReactTable .-pagination .-pageInfo {
  color: rgba(255, 255, 255, 0.87);
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin-left: 0.625rem;
}

.ReactTable .-pagination .-pageSizeOptions .a-dropdown .select-toggle {
  border: none;
}

.ReactTable .rt-noData {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 18.75rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 1.25rem;
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-resizable-header.rt-resizing {
  border-right: 0.0625rem solid #7d7d7d;
}

.ReactTable .rt-resizing .rt-td,
.ReactTable .rt-resizing .rt-th {
  -webkit-transition: none !important;
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable .rt-resizing .rt-td.rt-resizing,
.ReactTable .rt-resizing .rt-th.rt-resizing {
  border-right: 0.0625rem solid #7d7d7d;
}
