.container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  min-height: 0;
  grid-column: 1;
  grid-row: 2;
}


